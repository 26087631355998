import * as React from 'react'
import { useState, useEffect, RefObject } from 'react'
import { compose, withProps } from 'recompose'
import { withGoogleMap, Marker, Circle, GoogleMap } from 'react-google-maps'
import { GeoCoordinates } from './utils'
import { DEFAULT_GEOFENCING } from '../../constants/GEO_DATA'
import { Loader } from '@googlemaps/js-api-loader'

// https://github.com/tomchentw/react-google-maps/blob/master/src/constants.js#L1
// https://stackoverflow.com/questions/44296040/accessing-map-reference-using-react-google-maps-v6
// Don't konw why the constants are not exported
export const MAP = `__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED`

// Initialize the Google Maps loader
const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || '',
  version: 'weekly',
})

const GOOGLE_MAP_PARAMS = {
  containerElement: <div style={{ height: `100%`, minHeight: 300 }} />,
  mapElement: <div style={{ height: `100%`, minHeight: 300 }} />,
}

interface ReactGoogleMapProps {
  coordinates?: GeoCoordinates
  geofenceRadius?: number
  zoom?: number
  onMounted?: (input: any) => any
}


class RenderGoogleMapComponent extends React.Component<ReactGoogleMapProps> {
  componentDidMount() {
    this.getFitBounds()
  }

  componentDidUpdate(prevProps: ReactGoogleMapProps) {
    if (this.props.geofenceRadius !== prevProps.geofenceRadius) {
      this.getFitBounds()
    }
  }

  shouldComponentUpdate(nextProps: Readonly<ReactGoogleMapProps>): boolean {
    return (
      this.props.geofenceRadius !== nextProps.geofenceRadius ||
      this.props.zoom !== nextProps.zoom ||
      this.props.coordinates?.lat !== nextProps.coordinates?.lat ||
      this.props.coordinates?.lng !== nextProps.coordinates?.lng
    )
  }

  private mapRef: GoogleMap | undefined
  private circleRef: RefObject<Circle> = React.createRef()

  render() {
    const {
      coordinates = DEFAULT_GEOFENCING.location.coordinates,
      geofenceRadius,
      zoom = 14,
    }: ReactGoogleMapProps = this.props

    const circleColor = '#ffd600'

    const circleOptions = {
      center: coordinates,
      strokeColor: circleColor,
      strokeOpacity: 0.5,
      strokeWeight: 0,
      fillColor: circleColor,
      fillOpacity: 0.5,
    }

    return (
      <GoogleMap
        ref={this.onMapMounted.bind(this)}
        defaultZoom={12}
        defaultOptions={{ disableDefaultUI: true }}
        zoom={zoom}
        center={coordinates}
      >
        {!!geofenceRadius && (
          <Circle ref={this.circleRef} defaultRadius={0} radius={geofenceRadius} options={circleOptions} />
        )}
        <Marker position={coordinates} />
      </GoogleMap>
    )
  }

  private getFitBounds = () => {
    if (this.mapRef && this.circleRef.current) {
      const circle = this.circleRef.current
      const bounds = circle.getBounds()
      this.mapRef.fitBounds(bounds)
    }
  }

  private onMapMounted(map: any) {
    if (map) {
      this.props.onMounted?.(map)
      this.mapRef = map.context[MAP]
    }
  }
}

const ReactGoogleMap = compose<ReactGoogleMapProps, ReactGoogleMapProps>(
  withProps(GOOGLE_MAP_PARAMS),
  withGoogleMap,
)(RenderGoogleMapComponent)

// Create a wrapper component that ensures Google Maps API is loaded before rendering the map
// We don't want to rely on internal loading of react-google-maps, so we use our own loader
const ReactGoogleMapWrapper = (props: ReactGoogleMapProps) => {
  const [mapsLoaded, setMapsLoaded] = useState(false)

  useEffect(() => {
    const loadMapsApi = async () => {
      try {
        // First load the core Maps API if not already loaded
        if (!window.google?.maps) {
          await loader.load()
        }

        // Then load the necessary libraries using importLibrary

        // The geometry library provides spatial calculations needed for the bounds fitting
        await loader.importLibrary('geometry')
        // The drawing library supports the Circle overlay that displays the geofence radius visually
        await loader.importLibrary('drawing')

        setMapsLoaded(true)
      } catch (error) {
        console.error('Error loading Google Maps API:', error)
      }
    }

    loadMapsApi()
  }, [])

  // Only render the map component once Maps API is loaded
  if (!mapsLoaded) {
    return (
      <div
        style={{
          height: '100%',
          minHeight: 300,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        Loading map...
      </div>
    )
  }

  return <ReactGoogleMap {...props} />
}

export default ReactGoogleMapWrapper
